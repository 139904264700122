body {
  margin: 0;
  background-image: url(./img/fondbody.png);
  background-size: cover;
  background-position: center;
  font-family: sans-serif;
  font-size: 13px;
}

h2{
  font-family: serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
