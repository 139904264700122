/*=====MENU BURGER=====*/

.menu-toggle {
    display: none;
    cursor: pointer;
    padding: 10px;
}

.menu-icon {
    width: 30px;
    height: 2px;
    background-color: white;
    margin: 10px 0;
    transition: 0.4s;
}

.menu-icon::before,
.menu-icon::after {
    content: "";
    display: block;
    width: 30px;
    height: 2px;
    background-color: white;
    position: absolute;
    transition: 0.4s;
}

.menu-icon::before {
    top: 10px;
}

.menu-icon::after {
    bottom: 17px;
}

.menu-icon.open {
    background-color: transparent;
    transform: rotate(45deg);
}

.menu-icon.open::before {
    top: 0;
    transform: rotate(90deg);
}

.menu-icon.open::after {
    bottom: 0;
    transform: rotate(90deg);
}

/*=====NAV=====*/

.barnav {
    position: fixed;
    width: 100%;
    z-index: 1;
    display: flex;
    background-color: rgba(0, 0, 0, 0.7);
    height: 50px;
    justify-content: center;
}

.nav ul {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
    justify-content: space-around;

}

.nav {
    width: 90%;
    padding: 0 5%;

}

.nav li {
    padding: 15px 30px;
   
}

.nav a {
    text-decoration: none;
    color: white;
    transition: linear-gradient 0.3s;
    font-size: 15px;

}

.nav a:hover{
    color: rgb(59, 47, 38);
    background: linear-gradient(180deg, #FCEBAF 0%, #CBB878 100%);
}


@media screen and (max-width: 800px) {
    .menu-toggle {
        display: block;
    }

    .nav ul {
        display: none;
        position: absolute;
        top: 50px;
        left: 0;
        background-color: rgba(0, 0, 0, 0.7);
        width: 150px;
        flex-direction: column;
    }

    .nav ul.open {
        display: flex;
    }

    .nav li {
        padding: 10px 30px;
        border-bottom: none;
        text-align: left;
    }

    .menu-btn {
        width: 30px;
        height: 20px;
        position: relative;
    }

    .menu-btn div {
        width: 30px;
        height: 2px;
        background: white;
        margin: 6px 0;
        transition: 0.4s;
    }

    .menu-btn div.open {
        background: transparent;
    }

}

